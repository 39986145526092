<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="6" md="6">EXPRESS</v-col>
                    <v-col cols="6" md="6">
                        <v-row class="mt-0 mr-2" justify="end">
                            <span class="text-caption"><v-chip color="white"><strong class="text-body-2">{{txtSucursal}}</strong></v-chip></span>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row class="mt-1">
                    <v-col cols="12" md="8">
                        <v-row>
                            <v-col cols="6" md="4">
                                <v-text-field style="width:130px" id="barr"  label="Codigo Barra"
                                  @change="captureBarra(barra)" class='text-caption'
                                  @keypress="onlyNumber" v-model="barra" dense outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" md="8" class="text-center">
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-badge bordered overlap color="error" :content="pendientecuenta">
                                        <v-btn @click="pendiente=true" small color="indigo" dark rounded>
                                            <v-icon small>find_replace</v-icon>
                                            <span class="text-btn">Pendiente</span>
                                        </v-btn>                                
                                        </v-badge>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-btn @click="inventa=true" small color="indigo" dark rounded>
                                            <v-icon small>find_replace</v-icon>
                                            <span class="text-btn">Productos</span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="12" md="12">
                                <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event">
                                    <template v-slot:item.cantidad="{ item }">
                                        <v-edit-dialog @save="save(item,editCantidad)" @cancel="cancel()" @close="close()" @open="open()">
                                            <v-icon small class="ml-1 blue--text">edit</v-icon>
                                            <span class="ml-1">{{item.cantidad}}</span>
                                            <template v-slot:input>
                                                <div class="mt-4 text-h6">Cantidad</div>
                                                <v-text-field v-if="item.medida!='UN'" @keypress="onlyDecimal" v-model="editCantidad" single-line></v-text-field>
                                                <v-text-field v-else @keypress="onlyNumber" v-model="editCantidad" single-line></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template v-slot:item.menor="{ item }">
                                        <v-icon class="ml-1 red--text" @click="menos(item)">arrow_drop_down</v-icon>
                                    </template>
                                    <template v-slot:item.convierte="{ item }">
                                        <v-icon small v-if="item.medida!='UN'" @click="convierteFila(item)" color="success">track_changes</v-icon>
                                        <span v-else>-</span>
                                    </template>
                                    <template v-slot:item.accion="{ item }">
                                        <v-icon small @click="deleteFila(item)" color="red">delete</v-icon>
                                    </template>
                                    <template v-slot:item.precio="{ item }">
                                        <v-edit-dialog @save="savePrecio(item,editPrecio)" @cancel="cancel()" @close="close()" @open="open()">
                                            <v-icon small class="ml-1 blue--text">edit</v-icon>
                                            <span class="ml-1">{{item.precio}}</span>
                                            <template v-slot:input>
                                                <div class="mt-4 text-h6">Precio</div>
                                                <v-text-field @keypress="onlyNumber" v-model="editPrecio" single-line></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                </v-data-table>
                                <v-pagination v-model="page" :length="pageCount" :total-visible="5"></v-pagination>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-row>
                            <v-col cols="12" md="12">
                                <div v-if="ntipoDte==22">
                                    <v-row>
                                        <v-col cols="6" md="6">
                                            <v-btn block color="teal" dark>Boleta</v-btn>
                                        </v-col>
                                        <v-col cols="6" md="6">
                                            <v-btn @click="cualDte(5)" block color="grey" outlined>Factura</v-btn>                                
                                        </v-col>
                                    </v-row>
                                </div>
                                <div v-if="ntipoDte==5">
                                    <v-row>
                                        <v-col cols="6" md="6">
                                            <v-btn @click="cualDte(22)" block color="grey" outlined>Boleta</v-btn>                                
                                        </v-col>
                                        <v-col cols="6" md="6">
                                            <v-btn @click="cualDte(5)" block color="teal" dark>Factura</v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" >
                                <v-text-field class='text-caption' v-model="clienteExpress" label="Cliente" dense outlined filled disabled hint="rutExpress"></v-text-field>
                                <div class="mt-n7 ml-3"><span class="text-caption grey--text">{{rutExpress}}</span></div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n2">
                            <v-col cols="12" md="12" >
                                <v-text-field class='text-caption' v-model="envio" label="Envio Digital" dense outlined></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="12" md="5" >
                                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="text-caption" v-model="vencimiento" label="Ven." prepend-icon="event" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                                    </template>
                                    <v-date-picker v-model="vencimiento" @input="menu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="7" >
                                <v-select class='text-caption' v-model="pago" :items="itemPAGO" label="Forma Pago" item-text="forma" item-value="id" outlined dense></v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="8">
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-card color="blue-grey" dark>
                                    <v-card-title>
                                        <h6>NETO</h6>
                                        <v-spacer></v-spacer>
                                        <h5>$ {{new Intl.NumberFormat("de-DE").format(parseFloat(mto_totalNeto))}}</h5>
                                    </v-card-title>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-card color="blue-grey" dark>
                                    <v-card-title>
                                        <h6>IVA ({{impuesto}}%)</h6>
                                        <v-spacer></v-spacer>
                                        <h5>$ {{new Intl.NumberFormat("de-DE").format(parseFloat(mto_totalIva))}}</h5>
                                    </v-card-title>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-card color="blue-grey" dark>
                                    <v-card-title>
                                        <!-- <h6>TOTAL (<span style="font-size:7.5pt">Ajuste ${{totalAjuste}}</span>)</h6> -->
                                        <h6>TOTAL</h6>
                                        <v-spacer></v-spacer>
                                        <h5>$ {{new Intl.NumberFormat("de-DE").format(parseInt(mto_totalVenta))}}</h5>
                                    </v-card-title>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="1" class="mt-7 text-right">
                        <v-btn @click="add_pendiente()" small color="teal" dark rounded><v-icon small>add_circle_outline</v-icon><span class='text-btn'>Pendiente</span></v-btn>
                    </v-col>
                    <v-col cols="12" md="3" class="mt-7 text-right">
                        <v-btn @click="nuevaVenta()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                        <v-btn v-if="btnGrabar===true" @click="enviarDTE()" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                        <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                        <a v-if="btnPrt===true" target="_blank" :href="html" class="ml-2 mr-2"><v-icon color="teal">print</v-icon></a>
                        <a v-else href="javascript:void(0)" class="ml-3 mr-2"><v-icon class="grey--text">print</v-icon></a>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <!-- dialog inventa --> 
        <v-dialog v-model="inventa" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="6" md="3">
                        <v-btn @click="inventa=false" color="red" small dark><v-icon small>close</v-icon></v-btn>
                    </v-col>
                    <v-col cols="6" md="5">
                        <!-- <h6>Bodega : {{txtBodega}}</h6>                 -->
                    </v-col>
                    <v-col cols="12" md="4" class="text-right">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="inventasearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="handleProductos" dense dark :search="inventasearch" :headers="inventatitulo" :items="inventalista" :page.sync="inventapage" :items-per-page="inventaitemsPerPage"  @page-count="inventapageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="inventapage" :length="inventapageCount" :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>
        <!-- dialog clientes --> 
        <v-dialog v-model="clientes" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="clientes=false" color="red" small dark><v-icon small>close</v-icon></v-btn>
                <v-spacer>
                    <div class="float-right">
                    <v-text-field class='ml-5 mr-2 text-caption' v-model="clientesearch" label="Buscar Cliente" dense required></v-text-field>
                    </div>
                </v-spacer>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="handleClientes" dense dark :search="clientesearch" :headers="clientetitulo" :items="clientelista" :page.sync="clientepage" :items-per-page="clienteitemsPerPage"  @page-count="clientepageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="clientepage" :length="clientepageCount" :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>
        <!-- dialog convierte --> 
        <v-dialog v-model="convierte" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-btn class="mt-2 ml-2" @click="convierte=false" color="red" small dark><v-icon small>close</v-icon></v-btn>
            <v-card-title>
                Convertir Unidad de Medida ( 1 x ${{valorKG}} )
            </v-card-title>
            <v-card-text>
                <v-container>
                <v-row>
                    <v-col cols="12" md="3" class="text-center">
                        <v-text-field @keypress="onlyDecimal" v-model="cantidadKG" label="Cantidad" dense outlined :filled="filConvierte" :disabled="disConvierte"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center">
                        <v-btn @click="cantidad_precio()" dark color="indigo"><v-icon>fast_forward</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center">
                        <v-btn @click="precio_cantidad()" dark color="indigo"><v-icon>fast_rewind</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center">
                        <v-text-field @keypress="onlyNumber" v-model="precioKG" label="Precio" dense outlined :filled="filConvierte" :disabled="disConvierte"></v-text-field>                        
                    </v-col>
                </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="mt-n10">
                <v-btn @click="no_aplica()" color="success" small>LIMPIAR</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="btnAplica==true" @click="si_aplica()" color="success" small>APLICAR</v-btn>
                <v-btn v-else color="grey" small>APLICAR</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <!-- dialog pendiente --> 
        <v-dialog v-model="pendiente" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="6" md="3">
                        <v-btn @click="pendiente=false" color="red" small dark><v-icon small>close</v-icon></v-btn>
                    </v-col>
                    <v-col cols="6" md="5">
                        <!-- <h6>Bodega : {{txtBodega}}</h6>                 -->
                    </v-col>
                    <v-col cols="12" md="4" class="text-right">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="pendientesearch" label="Buscar" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-data-table @click:row="handlePendiente" dense dark :search="pendientesearch" :headers="pendientetitulo" :items="pendientelista" :page.sync="pendientepage" :items-per-page="pendienteitemsPerPage"  @page-count="pendientepageCount = $event" hide-default-footer class="elevation-1 text-size">
                        </v-data-table>
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-pagination v-model="pendientepage" :length="pendientepageCount" :total-visible="5"></v-pagination>
                    </v-col>
                    <v-col cols="12" md="4" class="mt-2">
                        <span class="text-caption">Eliminar Pendientes</span>
                        <v-btn text icon class="ml-2"><v-icon @click="deletePendiente()" color="red">delete</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
//import JsBarcode from 'jsbarcode'
export default {
    name:'Venta', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            clienteExpress:'Boleta DTE',
            rutExpress:'1-9',
            ntipoDte:22,  // (22) Boleta ; (5) Factura
            dialogoPago:false,
            orden:'',
            headers: [
                { text: 'Sku', align: 'start', sortable: true, value: 'sku', width:'15%' },
                { text: 'Producto', align: 'start', sortable: false, value: 'producto', width:'30%' },
                { text: 'Medida', align: 'center', sortable: false, value: 'medida', width:'5%' },
                { text: '', align: 'end', sortable: false, value: 'convierte', width:'10%' },
                { text: 'Cantidad', align: 'end', sortable: false, value: 'cantidad', width:'15%' },
                { text: '', align: 'start', sortable: false, value: 'menor', width:'5%' },
                { text: 'Precio', align: 'end', sortable: false, value: 'precio', width:'15%' },
                { text: '', align: 'center', sortable: false, value: 'accion', width:'5%' },
            ],
            items: [],
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,
            itemDTE:[
                {id:'22',doc:'Boleta'},
                {id:'5',doc:'Factura'},
            ],
            inventa:false,
            inventasearch:'',
            inventatitulo:[
                { text: 'Sku', align: 'start', sortable: true, value: 'sku', width:'' },
                { text: 'Producto', align: 'start', sortable: true, value: 'nameSku' },
                { text: 'Medida', align: 'start', sortable: true, value: 'medida' },
                { text: 'Stock', align: 'start', sortable: true, value: 'vistaStock' },
                //{ text: 'Stock', align: 'start', sortable: true, value: 'stockSku' },
            ],
            inventalista:[],
            inventapage: 1,
            inventapageCount: 0,
            inventaitemsPerPage: 10,
            clientes:false,
            clientesearch:'',
            clientetitulo:[
                { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                { text: 'Cliente', align: 'start', sortable: true, value: 'nombre' },
            ],
            clientelista:[],
            clientepage: 1,
            clientepageCount: 0,
            clienteitemsPerPage: 10,

            pendiente:false,
            pendientecuenta:0,
            search_id:0,
            pendientesearch:'',
            pendientetitulo:[
                { text: 'ID', align: 'center', sortable: false, value: 'pen_id', width:'' },
                { text: 'Fecha', align: 'center', sortable: false, value: 'pen_fecha' },
                { text: 'Hora', align: 'center', sortable: false, value: 'pen_hora' },
                { text: 'Total Sku', align: 'center', sortable: false, value: 'pen_total' },
            ],
            pendientelista:[],
            pendientepage: 1,
            pendientepageCount: 0,
            pendienteitemsPerPage: 10,

            totalVenta:0,
            totalNeto:0,
            totalAjuste:0,
            totalIva:0,

            mto_totalVenta:0,
            mto_totalNeto:0,
            mto_totalIva:0,
            mto_totalAjuste:0,

            btnGrabar:true,
            btnPrt:false,
            itemPAGO:[],
            barra:'',
            html:'',

            entidad:'',
            entidad_boleta:'',
            rut:'',
            razon:'',
            direccion:'',
            comuna:'',
            ciudad:'',
            giro:'',
            fecha:'',
            hoy:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            neto:0,
            impuesto:'',
            iva:0,
            total:0,
            dte:'',
            pago:'',
            
            OutID:0,
            descargaCSV:false,
            rutaCSV:'',   
            envio:'',
            nStock:0,
            txtStock:'si',
            nSucursal:0,
            txtSucursal:'',
            menu1:false,
            vencimiento:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            vendedor:0,
            nBodega:0,
            txtBodega:'Sin Bodega',
            editCantidad:'',
            idLocal:0,
            editPrecio:'',
            // Convierte
            filaConvierte:[],
            convierte:false,
            valorKG:0,
            upCantidad:0,
            cantidadKG:0,
            precioKG:0,
            filConvierte:false,
            disConvierte:false,
            btnAplica:false,
            //pos80
            pos:false,

        }
    },
    async mounted(){
        await this.buscaImpuesto();
        await this.focusBarra();
        this.loading=true;
        await this.buscaLocal();
        //await this.buscarSucursal();
        await this.newVenta();
        await this.cargaPagos('1-9');
        this.loading=false;
        //JsBarcode("#barcode", "123456");
        await this.search_pendientes();
    },
    methods:{
        async convierteFila(linea){
            this.filaConvierte=linea
            this.convierte=true;
            this.valorKG=linea.valor;
            this.cantidadKG=1;
            this.precioKG=linea.valor;
            this.filConvierte=false;
            this.disConvierte=false;
            this.btnAplica=false;
        },
        cantidad_precio(){
            if(this.cantidadKG.trim()!=''){
                if(!isNaN(this.cantidadKG)){
                    //aplicamos regla de 3
                    let valor=parseFloat(this.cantidadKG)*parseFloat(this.valorKG);
                    valor=parseInt(valor);
                    this.precioKG=valor;
                    this.upCantidad=this.cantidadKG;
                    this.filConvierte=true;
                    this.disConvierte=true;
                    this.btnAplica=true;
                } else {
                    this.Msg='Cantidad Invalida';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    newCantidad='';
                };
            }
        },
        precio_cantidad(){
            if(this.precioKG.trim()!=''){
                if(!isNaN(this.precioKG)){
                    //aplicamos regla de 3
                    let valor=parseFloat(this.precioKG)/parseFloat(this.valorKG);
                    this.upCantidad=parseFloat(valor);
                    valor=parseFloat(valor).toFixed(3);
                    this.cantidadKG=valor;
                    this.filConvierte=true;
                    this.disConvierte=true;
                    this.btnAplica=true;
                } else {
                    this.Msg='Cantidad Invalida';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    newCantidad='';
                };
            }
        },
        no_aplica(){
            this.upCantidad=0;
            this.cantidadKG=1;
            this.precioKG=this.valorKG;
            this.filConvierte=false;
            this.disConvierte=false;
            this.btnAplica=false;
        },
        si_aplica(){
            if(!isNaN(this.upCantidad)){
                this.save(this.filaConvierte,parseFloat(this.upCantidad));
                this.convierte=false
            } else {
                 this.no_aplica()
            }
        },
        async buscaImpuesto(){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/impuesto/'+this.contrato);
            const datos=response.data;
            this.impuesto=0;
            if (datos[0].existe=='si'){
                this.impuesto=datos[0].Iva
            }
        },
        async buscaLocal(){
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/usuarios/'+this.contrato+'/'+this.usu);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.idLocal=datos[0].Bodega;
                    this.nSucursal=this.idLocal;
                    this.vendedor=datos[0].ID;
                    this.nBodega=datos[0].Bodega;
                    const sucu=await this.buscarSucursal_v2();
                    if(sucu!=''){
                        this.txtSucursal=sucu;
                        this.txtBodega=sucu
                    } else {
                        this.txtSucursal=this.nSucursal;
                        this.txtBodega=this.nSucursal
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async buscarSucursal_v2(){
            let res = '';
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/bodegas/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        if(element.ID==this.nSucursal){
                            res = element.Nombre
                        };
                    });
                }                
            } catch (error) {
                console.log(error)
            };
            return res
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        cualDte(nDte){
            if(nDte==22){
                this.clienteExpress='Boleta DTE';
                this.rutExpress='1-9';
                this.entidad=this.entidad_boleta;
                this.envio='';
                this.ntipoDte=22;
                const timestamp = Date.now();
                this.orden=this.vendedor+''+timestamp.toString();
                //console.log(this.orden);
                this.cargaPagos(this.rutExpress);
            };
            if(nDte==5){
                this.clienteExpress='';
                this.rutExpress='.';
                this.envio='';
                this.entidad='';
                this.ntipoDte=5;
                this.clientes=true;
                const timestamp = Date.now();
                this.orden=this.vendedor+''+timestamp;
                //console.log(this.orden);
            };

        },
        async buscarProducto(){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/stock/datatable/v3/'+this.contrato);
            const datos=response.data;
            // const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/productos/'+this.contrato);
            // const datos=response.data;
            this.inventalista=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    if(parseFloat(element.Total)>0 && parseFloat(element.numBodega)==parseFloat(this.nBodega)){
                        this.inventalista.push({
                            sku:element.Sku,
                            barraSku:element.Barra,
                            nameSku:element.Nombre,
                            precioSku:element.PrecioLocal,
                            vistaStock:new Intl.NumberFormat("de-DE").format(parseFloat(element.Total).toFixed(3)),
                            stockSku:element.Total,
                            medida:element.Medida
                        });                    
                    }
                });
            }
        },
        handleProductos(fila) {
            //buscamos sku en la lista
            let esta='no';
            let imp=1+(this.impuesto/100);
            let precio_item=0;
            if(this.items.length!=0){
                this.items.forEach(element => {
                    if (element.sku==fila.sku){
                        esta='si';
                        element.valorCantidad=parseFloat(element.valorCantidad)+1;
                        element.cantidad=new Intl.NumberFormat("de-DE").format(parseFloat(element.valorCantidad));
                        precio_item=parseFloat(element.valor);
                        // element.valor=parseFloat(element.valor)+parseFloat(fila.precioSku);
                        // element.precio=new Intl.NumberFormat("de-DE").format(parseFloat(element.valor));
                    };
                });
            };
            if(esta=='no'){
                let unidad=fila.precioSku/imp;
                this.items.push({
                    sku:fila.sku,
                    producto:fila.nameSku,
                    precio:new Intl.NumberFormat("de-DE").format(parseFloat(fila.precioSku)),
                    valor:parseFloat(fila.precioSku),
                    unidad:unidad,
                    cantidad:1,
                    valorCantidad:1,
                    medida:fila.medida,
                    bodega:this.nBodega
                });
                precio_item=parseFloat(fila.precioSku);
            };
            // this.totalNeto=this.totalNeto+parseFloat(fila.precioSku);
            // this.totalIva=Math.round(((parseFloat(this.totalNeto)*19)/100),0);
            // this.totalVenta=parseFloat(this.totalNeto)+parseFloat(this.totalIva);
            this.totalVenta=this.totalVenta+precio_item;
            this.mto_totalVenta=this.totalVenta;

            this.totalNeto=this.totalVenta/imp;
            //this.totalNeto=parseFloat(this.totalNeto).toFixed(2);
            this.mto_totalNeto=parseFloat(this.totalNeto).toFixed(2);

            this.totalIva=(this.totalNeto*this.impuesto)/100;
            //this.totalIva=parseFloat(this.totalIva).toFixed(2);
            this.mto_totalIva=parseFloat(this.totalIva).toFixed(2);

            // ajuste no aplica para esta app
            this.totalAjuste=this.totalVenta-(this.totalNeto+this.totalIva);
            this.totalAjuste=0;

            if(parseFloat(this.totalNeto)<=0 || parseFloat(this.totalIva)<=0 || parseFloat(this.totalVenta)<=0){
                this.totalNeto=0;
                this.totalIva=0;
                this.totalVenta=0;
                this.mto_totalNeto=0;
                this.mto_totalIva=0;
                this.mto_totalVenta=0;
            }
            this.inventa=false;
        },
        async buscaCliente(){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/lista/clientes/'+this.contrato);
            const datos=response.data;
            this.clientelista=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    if(element.Rut==this.rutExpress){ 
                        this.entidad_boleta=element.ID;
                        this.entidad=this.entidad_boleta; 
                    };
                    this.clientelista.push({
                        entidad:element.ID,
                        nombre:element.Nombre,
                        rut:element.Rut,
                        email:element.Email,
                        direccion:element.Direccion,
                        comuna:element.Comuna,
                        ciudad:element.Ciudad,
                        giro:element.Observacion,
                        preciocli:element.Precio,
                    })        
                });
            }
        },
        handleClientes(fila) {
            this.clienteExpress=fila.nombre;
            this.rutExpress=fila.rut;
            this.envio=fila.email;
            this.entidad=fila.entidad;
            this.rut=fila.rut;
            this.razon=fila.nombre;
            this.direccion=fila.direccion;
            this.comuna=fila.comuna;
            this.ciudad=fila.ciudad;
            this.giro=fila.giro;
            this.clientes=false;
            this.cargaPagos(this.rutExpress);
            if(fila.preciocli!='Precio Detalle'){
                this.Msg='Cliente con precio especial, realice la venta por cotización'
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
            }
        },
        // async buscarSucursal(){
        //     const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/usuarios/'+this.contrato+'/'+this.usu);
        //     const datos=response.data;
        //     if (datos[0].existe=='si'){
        //         if (datos[0].Sucursal!=0){
        //             this.nSucursal=datos[0].Sucursal;
        //             this.vendedor=datos[0].ID;
        //             this.nBodega=datos[0].Bodega;
        //             //buscar nombre de la bodega
        //             const response0 = await axios.get(process.env.VUE_APP_URL + '/api/inv/bodegas/'+this.contrato);
        //             const datos0=response0.data;
        //             if (datos0[0].existe=='si'){
        //                 datos0.forEach(element => {
        //                     if(element.ID==this.nBodega){
        //                         this.txtBodega=element.Nombre                            
        //                     }
        //                 });
        //             };
        //             //buscar nombre de la sucursal
        //             const response1 = await axios.get(process.env.VUE_APP_URL + '/api/inv/sucursales/'+this.contrato);
        //             const datos1=response1.data;
        //             if (datos1[0].existe=='si'){
        //                 datos1.forEach(element => {
        //                     if(element.ID==this.nSucursal){
        //                         this.txtSucursal=element.Nombre                            
        //                     }
        //                 });
        //             } else {  
        //                 this.txtSucursal='Sin Sucursal';
        //                 this.nSucursal=0;
        //             }
        //         } else {
        //             this.txtSucursal='Sin Sucursal';
        //             this.nSucursal=0;
        //         }
        //     } else { 
        //         this.txtSucursal='Sin Sucursal';
        //         this.nSucursal=0;
        //     }
        // },
        async cargaPagos(xRut){
            try {
                var auth='NO';
                const res = await axios.get(process.env.VUE_APP_URL + '/api/inv/clientes/'+this.contrato+'/'+ xRut);
                const dat=res.data;
                if (dat[0].existe=='si'){ auth=dat[0].Credito };            
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/DTE/forma/pago/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.itemPAGO=[];
                    datos.forEach(element => {
                        if(element.CodigoBsale=='4'){
                            if(auth=='SI'){
                                this.itemPAGO.push({
                                    'id':element.CodigoBsale,
                                    'forma':element.Descripcion
                                })
                            }
                        } else {
                            this.itemPAGO.push({
                                'id':element.CodigoBsale,
                                'forma':element.Descripcion
                            })
                        }
                    });
                }                
            } catch (error) {
                console.log(error)
            }
            // const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/DTE/forma/pago/'+this.contrato);
            // const datos=response.data;
            // if (datos[0].existe=='si'){
            //     this.itemPAGO=[];
            //     datos.forEach(element => {
            //         this.itemPAGO.push({
            //             'id':element.CodigoBsale,
            //             'forma':element.Descripcion
            //         })
            //     });
            // }
        },
        captureBarra(codBarra){
            if(this.barra.trim()!=''){
                const resultado = this.inventalista.find( datos => datos.barraSku == codBarra );
                if(resultado){
                    this.handleProductos(resultado);
                } else {
                    //console.log('Producto no Existe');
                    this.Msg='Producto no Existe'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                };
            };
            this.focusBarra();
            //document.getElementById("barr").dispatchEvent(new KeyboardEvent("keydown", {keyCode: 13}))
        },
        focusBarra(){
            var elemento = document.getElementById("barr");
            elemento.focus();
            this.barra=''
            //elemento.blur();
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
                this.captureBarra(this.barra);
            }
        },
        onlyDecimal ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        async nuevaVenta(){
            //console.log(this.items);
            this.loading=true;
            await this.newVenta();
            this.loading=false;
        },
        async newVenta(){
            this.btnGrabar=true;
            this.btnPrt=false;
            this.hoy=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.vencimiento=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.pago='';
            this.rut='';
            this.entidad='';
            this.razon='';
            this.direccion='';
            this.comuna='';
            this.ciudad='';
            this.giro='';
            this.totalNeto=0;
            this.totalAjuste=0;
            this.totalIva=0;
            this.totalVenta=0;
            this.mto_totalNeto=0;
            this.mto_totalAjuste=0;
            this.mto_totalIva=0;
            this.mto_totalVenta=0;
            this.items=[];
            this.clienteExpress='Boleta DTE';
            this.rutExpress='1-9';
            this.envio='';
            this.ntipoDte=22;
            const timestamp = Date.now();
            this.orden=this.vendedor+''+timestamp.toString();
            //Refrescar tablas
            await this.buscarProducto();
            await this.buscaCliente();
        },
        async enviarDTE(){
            this.loading=true;
            //console.log(this.entidad);
            if(this.nSucursal!=0){
                if(this.totalNeto!=0 && this.totalIva!=0 && this.totalVenta!=0){
                    if(this.rutExpress!='.'){
                        if(this.pago!=''){
                            const stateCotiza=await this.saveCotiza();
                            if(stateCotiza){
                                var sigla=this.contrato.substring(0,3);
                                //console.log(sigla);
                                if (sigla=='INV') {
                                    await this.saveDTE();
                                } else {
                                    if (sigla=='NDV'){
                                        await this.outDTE();
                                    } else {
                                        this.Msg='Error de Contrato'
                                        this.msgbox=true;
                                        this.color='red';
                                        this.showSnack();
                                    }
                                }
                            } else {
                                this.Msg='Inconsistencia en datos'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            }
                            this.btnGrabar=false;
                        } else {
                            this.Msg='Seleccione Forma de Pago'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } else {
                            this.Msg='Seleccione Cliente'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                    }
                } else {
                    this.Msg='Sin Total para vender'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Vendedor sin Sucursal'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            };
            this.loading=false;
        },
        async saveCotiza(){
            let todos='si';
            if(this.items.length!=0){
                for (let index = 0; index < this.items.length; index++) {
                    const element = this.items[index];
                    var parametros={
                        'contrato': this.contrato,
                        'orden': this.orden,
                        'entidad': this.entidad,
                        'tipo': 'Neto',
                        'cv': 'V',
                        'sku':element.sku,
                        'producto':element.producto,
                        'precio':element.valor,
                        'cantidad':element.valorCantidad,
                        'bodega': element.bodega,
                        'observa':'',
                        'usuario':this.usu,
                        'referencia':'0'};
                    const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/compraventa/nueva',parametros);
                    const datos=response.data;
                    //console.log(datos);
                    if (datos!='si'){ 
                        todos='no'
                    }
                };
                if(todos!='si'){
                    // eliminar cotiza
                    const response = await axios.delete(process.env.VUE_APP_URL + '/api/inv/orden/delete/'+this.contrato+'/'+this.orden);
                    const datos=response.data;
                    return false;
                } else {
                    console.log('Cotizacion Grabada');
                    return true
                }
            } else {
                return false
            }
        },
        async outDTE(){
            console.log(this.items);
            const selectDTE=this.ntipoDte;
            const selectPago=this.pago;
            if (parseFloat(this.totalVenta)!=0){
                if (selectDTE!=0 && selectPago!=0){
                    this.loading=true;
                    this.btnGrabar=false;
                    this.OutID=0;
                    //obtener ID nuevo del dte
                    const params={
                        'contrato':this.contrato,
                        'orden':this.orden,
                        'estado':'V',
                        'usuario':this.usu
                    };
                    try {
                        const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/DTE/id/new/v2/',params);
                        const res = response.data;
                        if (res=='si'){
                            const response1 = await axios.get(process.env.VUE_APP_URL + '/api/inv/DTE/id/new/v2/'+this.contrato+'/'+this.orden+'/'+this.usu);
                            const res1 = response1.data;
                            if (res1[0].existe=='si'){
                                this.OutID=parseFloat(res1[0].Folio); 
                            }                            
                        };
                        if (this.OutID!=0){
                            //console.log(this.OutID);
                            //confirmar que no existe en DTE
                            const response2 = await axios.get(process.env.VUE_APP_URL + '/api/inv/DTE/one/'+this.contrato+'/'+this.OutID);
                            const res2 = response2.data;
                            if (res2[0].existe=='no'){ 
                                this.html=process.env.VUE_APP_URL + '/api/inv/prt/venta/v80/'+this.contrato+'/'+this.OutID+'/'+this.entidad;
                                const paramsDTE={
                                    'contrato':this.contrato,
                                    'numero':this.OutID,
                                    'tipoDte':selectDTE,
                                    'tipoPago':selectPago,
                                    'neto':this.totalNeto,
                                    'iva':this.totalIva,
                                    'total':this.totalVenta,
                                    'fecha':this.hoy,
                                    'vencimiento':this.vencimiento,
                                    'orden':this.orden,
                                    'rutaPDF':this.html,
                                    'rutaXML':this.html,
                                    'usuario':this.usu,
                                    'sucursal':this.nSucursal
                                };     
                                await this.baseDTE(paramsDTE);
                            } else {
                                this.Msg='No Existen Folios'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            }
                        } else {
                            this.Msg='Folio No Asignado'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    this.Msg='Seleccione DTE y Pago'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Sin Productos'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.loading=false;
        },        
        async saveDTE(){
            const selectDTE=this.ntipoDte;
            const selectPago=this.pago;
            if (selectDTE!=0 && selectPago!=0){                
                // obtenemos el token del contrato
                let valToken='';
                const resToken = await axios.get(process.env.VUE_APP_URL + '/api/inv/token/access/'+this.contrato);
                const respuesta = resToken.data;
                if (respuesta[0].existe=='si'){ 
                    valToken=respuesta[0].Token;
                } else { console.log('Token Invalido') }
                // fecha cotizacion formato Bsale
                let intFecha=Date.parse(this.hoy);
                let strFecha=intFecha.toString();
                strFecha=strFecha.substring(0,10);
                const numFecha=parseFloat(strFecha);
                // fecha actual formato Bsale
                let intHoy=Date.parse(this.hoy);
                let strHoy=intHoy.toString();
                strHoy=strHoy.substring(0,10);
                const numHoy=parseFloat(strHoy);
                // fecha vencimiento formato Bsale
                let intVence=Date.parse(this.vencimiento);
                let strVence=intVence.toString();
                strVence=strVence.substring(0,10);
                const numVence=parseFloat(strVence);
                // creamos json Bsale
                let pCliente={
                    "code": this.rut,
                    "city": this.ciudad,
                    "company": this.razon,
                    "municipality": this.comuna,
                    "activity": this.giro,
                    "address": this.direccion
                };
                let pDetalle=[];
                this.items.forEach(element => {
                    pDetalle.push({
                        //"variantId":1,
                        "netUnitValue":parseFloat(element.unidad).toFixed(4),
                        "quantity": parseFloat(element.valorCantidad).toFixed(4),
                        //"taxId": "[1,2]",
                        "comment": element.producto,
                        //"discount": 0,
                        "taxes": [
                            {
                            "code": 14,
                            "percentage": 19
                            }
                        ]
                    })        
                });
                let pPago=[
                    {
                        "paymentTypeId": selectPago,
                        "amount": this.totalVenta,
                        "recordDate": numHoy
                    }
                ];
                let pReferencia=[
                    {
                        "number": this.orden,
                        "referenceDate": numFecha,
                        "reason": "Orden de Compra "+this.orden,
                        "codeSii": 801
                    }
                ];
                //console.log(selectDTE);
                var emitDTE;
                if(selectDTE!=22){ // factura
                    emitDTE=
                    {
                        "documentTypeId": selectDTE,
                        "officeId": 1,
                        "emissionDate": numHoy,
                        "expirationDate": numVence,
                        "declareSii": 1,
                        //"priceListId": 18,
                        "client": pCliente,
                        "details": pDetalle,
                        "payments": pPago,
                        "references": pReferencia
                    }
                } else { // boleta
                    emitDTE=
                    {
                        "documentTypeId": selectDTE,
                        //"documentTypeId": 1,
                        "officeId": 1,
                        "emissionDate": numHoy,
                        "expirationDate": numHoy,
                        "declareSii": 1,
                        //"priceListId": 18,
                        "details": pDetalle
                        //"payments": pPago,
                    }
                }
                // consumir api Bsale para crear DTE
                let resDTE =[];
                //console.log(valToken);
                //console.log(emitDTE);
                try {
                    const response = await axios.post('https://api.bsale.cl/v1/documents.json', emitDTE , {headers : {"Accept":"application/json","content-type":"application/json","access_token":valToken}});
                    resDTE = response.data;
                    //console.log(resDTE);
                } catch (error) {
                    console.log (error);
                }
                if (resDTE.length!=0){
                    this.envioDTE(this.envio,resDTE.urlPdf,resDTE.urlXml);
                    this.html=resDTE.urlPdf;
                    this.btnPrt=true;
                    //this.btnGrabar=false;
                    this.OutID=0;
                    this.OutID=resDTE.number;                    
                    const paramsDTE={
                        'contrato':this.contrato,
                        'numero':resDTE.number,
                        'tipoDte':selectDTE,
                        'tipoPago':selectPago,
                        'neto':this.totalNeto,
                        'iva':this.totalIva,
                        'total':this.totalVenta,
                        'fecha':this.hoy,
                        'vencimiento':this.vencimiento,
                        'orden':this.orden,
                        'rutaPDF':resDTE.urlPdf,
                        'rutaXML':resDTE.urlXml,
                        'usuario':this.usu,
                        'sucursal':this.nSucursal
                    };                    
                    await this.baseDTE(paramsDTE);
                } else {
                    this.Msg='DTE no enviado'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
                //console.log(res);
            } else {
                this.Msg='Seleccione DTE y Pago'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async baseDTE(parametrosDTE){
            const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/DTE/nueva',parametrosDTE);
            const datos=response.data;
            //console.log(parametrosDTE);
            if (datos=='si'){ 
                await this.updateReferencia();
                if(parseInt(this.search_id)!=0){
                    await this.deletePendiente();
                }
            } else {
                this.Msg='DTE enviado sin registro Local'
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
            }
        },
        async updateReferencia(){
            var parametros0={'numero': this.OutID};
            const response0 = await axios.put(process.env.VUE_APP_URL + '/api/inv/referencia/actualiza/'+this.contrato+'/'+this.orden+'/'+this.entidad,parametros0);
            const datos0=response0.data;
            //console.log(datos0);
             if (datos0=='si'){
                await this.UpdateStock();
             } else {
                this.Msg='DTE enviado sin Referencia';
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
             }
        },
        async UpdateStock(){
            let t=0;
            if (this.items.length!=0) {
                for (var i = 0; i < this.items.length; i++) {
                    var parametros1={
                            'contrato': this.contrato,
                            'sku': this.items[i].sku,
                            'cantidad': this.items[i].valorCantidad,
                            'signo': '0-',
                            'bodega': this.items[i].bodega,
                            'usuario': this.usu,
                            'observa': '',
                            'origen':'Venta'};
                    const response1 = await axios.post(process.env.VUE_APP_URL + '/api/inv/stock',parametros1);
                    const datos1=response1.data;
                    if (datos1=='si'){ t++ }
                };
                if (t!=this.items.length) {
                    this.Msg='DTE enviado sin actualizar Stock';
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                } else {
                    this.btnPrt=true;
                    this.Msg='DTE exitoso';
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                }
            }
        },
        async fileCSV(){
            if (this.btnPrt===true){
                this.loading=true;
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/fileCsv/venta/'+this.contrato+'/'+this.OutID+'/'+this.entidad);
                const datos=response.data;
                //console.log(datos);
                if (datos=='si'){
                    this.descargaCSV=true;
                    this.rutaCSV=process.env.VUE_APP_URL + '/buzon/'+this.contrato+'/'+this.OutID+'.csv';
                }
                this.loading=false;
            }
        },        
        async envioDTE(datoEnvio,datoPDF,datoXML){
            if (datoEnvio.trim()!=''){
                let InstFormData = new FormData();
                InstFormData.append('email' , datoEnvio);
                InstFormData.append('pdf' , datoPDF);
                InstFormData.append('xml' , datoXML);
                const response= await axios.post(process.env.VUE_APP_URL + '/api/server/sender', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                //console.log(response.data)
            }
        },
        async deleteFila(fila){
            let imp=1+(this.impuesto/100);            
            this.totalVenta=this.totalVenta-(parseFloat(fila.valor)*parseFloat(fila.valorCantidad));
            
            this.totalNeto=this.totalVenta/imp;
            //this.totalNeto=parseFloat(this.totalNeto).toFixed(2);
            this.mto_totalNeto=parseFloat(this.totalNeto).toFixed(2);

            this.totalIva=(this.totalNeto*this.impuesto)/100;
            //this.totalIva=parseFloat(this.totalIva).toFixed(2);
            this.mto_totalIva=parseFloat(this.totalIva).toFixed(2);

            //asignar total
            this.mto_totalVenta=parseFloat(this.totalVenta);

            if(parseFloat(this.totalNeto)<=0 || parseFloat(this.totalIva)<=0 || parseFloat(this.totalVenta)<=0){
                this.totalNeto=0;
                this.totalIva=0;
                this.totalVenta=0;
                this.mto_totalNeto=0;
                this.mto_totalIva=0;
                this.mto_totalVenta=0;
            };
            this.items = this.items.filter(function(dat) {
                 return dat.sku !== fila.sku; 
            });
            this.focusBarra();
        },
        async menos(fila){
            let imp=1+(this.impuesto/100);
            if(parseFloat(fila.valorCantidad)>1){
                fila.valorCantidad=parseFloat(fila.valorCantidad)-1;
                //let imp=1+(this.impuesto/100);
                if(fila.medida=='UN'){
                    fila.cantidad=new Intl.NumberFormat("de-DE").format(parseFloat(fila.valorCantidad));
                } else {
                    fila.cantidad=new Intl.NumberFormat("de-DE").format(parseFloat(fila.valorCantidad).toFixed(3));
                };
                this.totalVenta=this.totalVenta-parseFloat(fila.valor);
                // this.totalNeto=this.totalVenta/imp;
                // this.totalNeto=parseFloat(this.totalNeto).toFixed(2);
                // this.totalIva=(this.totalNeto*this.impuesto)/100;
                // this.totalIva=parseFloat(this.totalIva).toFixed(2);
                // this.totalAjuste=this.totalVenta-(this.totalNeto+this.totalIva);
                // this.totalAjuste=0;
                // this.focusBarra();
            } else {
                if (parseFloat(fila.valorCantidad)>0){
                    this.totalVenta=parseFloat(this.totalVenta)-(parseFloat(fila.valor)*parseFloat(fila.valorCantidad));
                    fila.valorCantidad=0;
                    fila.cantidad=0;
                }
            };
            this.totalNeto=this.totalVenta/imp;
            //this.totalNeto=parseFloat(this.totalNeto).toFixed(2);
            this.mto_totalNeto=parseFloat(this.totalNeto).toFixed(2);
            this.totalIva=(this.totalNeto*this.impuesto)/100;
            //this.totalIva=parseFloat(this.totalIva).toFixed(2);
            this.mto_totalIva=parseFloat(this.totalIva).toFixed(2);
            //asignar total
            this.mto_totalVenta=parseFloat(this.totalVenta);

            if(parseFloat(this.totalNeto)<=0 || parseFloat(this.totalIva)<=0 || parseFloat(this.totalVenta)<=0){
                this.totalNeto=0;
                this.totalIva=0;
                this.totalVenta=0;
                this.mto_totalNeto=0;
                this.mto_totalIva=0;
                this.mto_totalVenta=0;
            };

            //Ajuste no aplica para esta app
            this.totalAjuste=this.totalVenta-(this.totalNeto+this.totalIva);
            this.totalAjuste=0;
            if(parseFloat(fila.valorCantidad)<=0){
                this.items = this.items.filter(function(dat) {
                    return dat.sku !== fila.sku; 
                });
            };
            this.focusBarra();
        },
        savePrecio(fila,newPrecio){
            if(newPrecio!=''){
                if(parseFloat(newPrecio)!=0){
                    let imp=1+(this.impuesto/100);
                    let newUnidad=newPrecio/imp;
                    const resta=(parseFloat(fila.valor)*parseFloat(fila.valorCantidad));
                    this.totalVenta=this.totalVenta-parseFloat(resta);
                    const suma=(parseFloat(newPrecio)*parseFloat(fila.valorCantidad));
                    this.totalVenta=parseFloat(this.totalVenta)+parseFloat(suma);
                    fila.valor=newPrecio;
                    fila.precio=new Intl.NumberFormat("de-DE").format(parseFloat(newPrecio));
                    fila.unidad=newUnidad;

                    this.totalNeto=this.totalVenta/imp;
                    //this.totalNeto=parseFloat(this.totalNeto).toFixed(2);
                    this.mto_totalNeto=parseFloat(this.totalNeto).toFixed(2);

                    this.totalIva=(this.totalNeto*this.impuesto)/100;
                    //this.totalIva=parseFloat(this.totalIva).toFixed(2);
                    this.mto_totalIva=parseFloat(this.totalIva).toFixed(2);
                    
                    //ajuste no aplica para esta app
                    this.totalAjuste=this.totalVenta-(this.totalNeto+this.totalIva);
                    this.totalAjuste=0;

                    //asignar total
                    this.mto_totalVenta=parseFloat(this.totalVenta);

                    if(parseFloat(this.totalNeto)<=0 || parseFloat(this.totalIva)<=0 || parseFloat(this.totalVenta)<=0){
                        this.totalNeto=0;
                        this.totalIva=0;
                        this.totalVenta=0;
                        this.mto_totalNeto=0;
                        this.mto_totalIva=0;
                        this.mto_totalVenta=0;
                    }

                    this.editPrecio='';
                }
            };
            this.focusBarra();
        },
        save(fila,newCantidad) {
            console.log('save');
            if(!isNaN(newCantidad)){
                newCantidad=newCantidad;
            } else {
                this.Msg='Cantidad Invalida';
                this.msgbox=true;
                this.color='red';
                this.showSnack();
                newCantidad='';
            };
            if(newCantidad!=''){
                if(parseFloat(newCantidad)!=0){
                    //verificamos el stock
                    const resultado = this.inventalista.find( datos => datos.sku === fila.sku );
                    if (resultado){
                        if(parseFloat(resultado.stockSku)>=parseFloat(newCantidad)){
                            let imp=1+(this.impuesto/100);
                            const resta=(parseFloat(fila.valor)*parseFloat(fila.valorCantidad));
                            this.totalVenta=parseFloat(this.totalVenta)-parseFloat(resta);
                            const suma=(parseFloat(fila.valor)*parseFloat(newCantidad));
                            this.totalVenta=parseFloat(this.totalVenta)+parseFloat(suma);
                            if(fila.medida=='UN'){
                                fila.cantidad=new Intl.NumberFormat("de-DE").format(parseFloat(newCantidad));
                            } else {
                                fila.cantidad=new Intl.NumberFormat("de-DE").format(parseFloat(parseFloat(newCantidad).toFixed(3)));
                            };
                            fila.valorCantidad=newCantidad;
                            this.totalNeto=this.totalVenta/imp;
                            //this.totalNeto=parseFloat(this.totalNeto).toFixed(2);
                            this.mto_totalNeto=parseFloat(this.totalNeto).toFixed(2);

                            this.totalIva=(this.totalNeto*this.impuesto)/100;
                            //this.totalIva=parseFloat(this.totalIva).toFixed(2);
                            this.mto_totalIva=parseFloat(this.totalIva).toFixed(2);

                            //asignar total
                            this.mto_totalVenta=parseFloat(this.totalVenta);

                            if(parseFloat(this.totalNeto)<=0 || parseFloat(this.totalIva)<=0 || parseFloat(this.totalVenta)<=0){
                                this.totalNeto=0;
                                this.totalIva=0;
                                this.totalVenta=0;
                                this.mto_totalNeto=0;
                                this.mto_totalIva=0;
                                this.mto_totalVenta=0;
                            }
                            this.editCantidad='';
                        } else {
                            this.Msg='Cantidad Supera Stock';
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    }
                }
            };
            this.focusBarra();
        },
        cancel(fila) {
            console.log('cancel');
            this.editCantidad='';
            this.editPrecio='';
            this.focusBarra();
        },
        open(fila) {
            console.log('open');
            this.editCantidad='';
            this.editPrecio='';
            this.focusBarra();
        },
        close(fila) {
            console.log('close');
            this.editCantidad='';
            this.editPrecio='';
            this.focusBarra();
        },
        async add_pendiente(){
            try {
                if(this.items.length!=0){
                    let lineas = 0;
                    const timestamp = new Date();
                    const v_anio = ''+timestamp.getFullYear();
                    const v_mes = ''+(timestamp.getMonth() + 1);
                    const v_dia = ''+timestamp.getDate();
                    const v_hora = ''+timestamp.getHours();
                    const v_minuto = ''+timestamp.getMinutes();
                    const v_segundo = ''+timestamp.getSeconds();
                    const var_id = v_anio + v_mes + v_dia + v_hora + v_minuto + v_segundo;
                    const var_fecha = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
                    const var_hora = v_hora + ':' + v_minuto;
                    for (let index = 0; index < this.items.length; index++) {
                        const element = this.items[index];
                        const params = {
                            id: var_id,
                            contrato: this.contrato,
                            fecha: var_fecha, 
                            hora: var_hora,
                            sku: element.sku,
                            producto: element.producto,
                            medida: element.medida,
                            cantidad: element.valorCantidad,
                            precio: element.valor,
                            usuario: this.usu
                        };
                        const response1 = await axios.post(process.env.VUE_APP_URL + '/api/inv/save/pendiente/express',params);
                        const datos1=response1.data;
                        if (datos1=='si'){ lineas ++ }
                    };
                    if(lineas!=0){
                        this.Msg='Productos reservados de la lista';
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                        await this.search_pendientes();
                        await this.nuevaVenta();
                    }
                } else {
                    this.Msg='No existen productos en la lista';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } catch (error) {
                console.log(error)
            }
        },
        async search_pendientes(){
            try {
                this.pendientelista=[];
                this.pendientecuenta=0;
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/search/pendiente/express/'+this.contrato+'/'+this.usu);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        this.pendientelista.push({
                            pen_id: element.ID,
                            pen_fecha: element.Fecha,
                            pen_hora: element.Hora,
                            pen_total: element.Total
                        }); this.pendientecuenta++
                    });
                }
            } catch (error) {
                console.log(error)
            }
        },
        async handlePendiente(fila){
            this.pendiente=false;
            await this.nuevaVenta();
            this.search_id= fila.pen_id;
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/search/pendiente/express/'+this.contrato+'/'+this.usu+'/'+this.search_id);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    let imp = 1+(parseFloat(this.impuesto)/100);
                    datos.forEach(element => {
                        let unidad=parseFloat(element.Precio)/imp;
                        this.items.push({
                            sku: element.Sku,
                            producto: element.Producto,
                            precio: new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                            valor: parseFloat(element.Precio),
                            unidad: unidad,
                            cantidad: new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                            valorCantidad: parseFloat(element.Cantidad),
                            medida:element.Medida,
                            bodega:this.nBodega
                        });
                        //recalculamos los totales
                        const precio_item=parseFloat(element.Precio) * parseFloat(element.Cantidad);

                        this.totalVenta=this.totalVenta+precio_item;
                        this.mto_totalVenta=this.totalVenta;

                        this.totalNeto=this.totalVenta/imp;
                        this.mto_totalNeto=parseFloat(this.totalNeto).toFixed(2);

                        this.totalIva=(this.totalNeto*this.impuesto)/100;
                        this.mto_totalIva=parseFloat(this.totalIva).toFixed(2);

                        // ajuste no aplica para esta app
                        this.totalAjuste=this.totalVenta-(this.totalNeto+this.totalIva);
                        this.totalAjuste=0;
                    });
                    if(parseFloat(this.totalNeto)<=0 || parseFloat(this.totalIva)<=0 || parseFloat(this.totalVenta)<=0){
                        this.totalNeto=0;
                        this.totalIva=0;
                        this.totalVenta=0;
                        this.mto_totalNeto=0;
                        this.mto_totalIva=0;
                        this.mto_totalVenta=0;
                    }
                }              
            } catch (error) {
                console.log(error)
            };
        },
        async deletePendiente(){
            try {
                if(this.pendientelista.length!=0){
                    const response = await axios.delete(process.env.VUE_APP_URL + '/api/inv/pendiente/express/delete/'+this.contrato+'/'+this.usu);
                    const datos=response.data;
                    if (datos=='si'){ 
                        this.search_id = 0;
                        await this.search_pendientes()
                    }
                } else {
                    this.Msg='No existen pendientes';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } catch (error) {
                console.log(error)
            }
        },
        async deleteLinePeniente(fila){
            this.search_id= fila.pen_id;
            await this.deletePendiente();
            this.pendiente=false;
        },
    }
}
</script>